
import Vue from 'vue';

import KeyResponse from 'Entities/apiManagement/KeyResponse';
import { ICreateKeyPayload } from 'Entities/apiManagement/CreateKeyPayload';
import { getApiKeys, createApiKey, deleteApiKey } from 'Store/v2/ApiManagment';
import { IDeleteKeyPayload } from 'Lib/entities/apiManagement/DeleteKeyPayload';
import TextInput from 'Control/TextInput.vue';
import SwitchControl from 'Control/Switch.vue';
import Icon from 'UI/Icon.vue';
import Button from 'Control/Button.vue';
import Guid from 'UI/Guid.vue';
import theme from 'Theme';

type Scope = KeyResponse['scopes'][0];

interface Data {
    newKeyName: string;
    newKeyScopes: Set<Scope>;
    errors: Record<string, boolean>;
}
interface Methods {
    onScopeChange: (scope: Scope) => void;
    onKeyCreate: () => void;
    deleteKey: (apiKey: string) => void;
    openInNewWindow: (data: string) => void;
    validateName: () => boolean;
}
interface Computed {
    apiKeys: KeyResponse[];
}

export default Vue.extend<Data, Methods, Computed>({
    name: 'ManageApiKeys',
    components: {
        Guid,
        Button,
        Icon,
        SwitchControl,
        TextInput,
    },
    data() {
        return {
            newKeyName: '',
            newKeyScopes: new Set(),
            errors: {
                name: false,
            },
            theme,
        };
    },
    computed: {
        apiKeys() {
            return this.$store.state.ApiManagment.apiKeys;
        },
    },

    mounted() {
        this.$store.dispatch(getApiKeys(undefined));
    },
    methods: {
        onScopeChange(scope) {
            const newScopes = new Set(this.newKeyScopes);
            if (newScopes.has(scope)) {
                newScopes.delete(scope);
            } else {
                newScopes.add(scope);
            }
            this.newKeyScopes = newScopes;
        },
        validateName() {
            this.newKeyName = this.newKeyName.trim();
            if (this.newKeyName.length < 3 || this.newKeyName.length > 20) {
                return false;
            }
            const regExp = /^[a-zA-Z0-9 ]+$/;
            return regExp.test(this.newKeyName);
        },
        async onKeyCreate() {
            if (Array.from(this.newKeyScopes.values()).length === 0 || !this.validateName()) {
                if (!this.validateName()) {
                    this.errors.name = true;
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Enter valid key name');
                } else {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Select at least one policy');
                }
                return;
            }
            const req: ICreateKeyPayload = {
                scopes: Array.from(this.newKeyScopes.values()),
                name: this.newKeyName,
                totp: '',
            };
            await this.$store.dispatch(createApiKey(req));
        },
        deleteKey(key) {
            const req: IDeleteKeyPayload = {
                apiKey: key,
                totp: '',
            };
            this.$store.dispatch(deleteApiKey(req));
        },
        openInNewWindow(link) {
            window.open(link);
        },
    },
});
